.badgeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 42px;
  height: 60px;

  &:hover {
    .badgeCardList {
      opacity: 1;
      z-index: 1038;
      top: 60px;
    }
  }

  .ant-badge {
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    padding: 0 8px;
    border-radius: 8px;
    &:hover {
      background: #f1f2f4;
    }

    .ant-scroll-number {
      top: 10px;
      transform: translate(30%, -50%);
    }
  }

  .icon {
    height: 24px;
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    color: #000000;
  }

  .badgeCardList {
    position: absolute;
    top: 60px;
    right: 0px;
    width: 387px;
    //height: 316px;
    height: auto;
    z-index: -1;
    opacity: 0;
    background-color: #ffffff;
    border: none;
    padding: 0 12px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    transition: all 0.1s ease-in-out;

    .ant-list-header {
      padding: 24px;
      font-weight: 600;
    }

    .ant-list-item {
      padding: 12px;
      margin: 0 12px;
      border: none;
      border-radius: 8px;
      align-items: flex-end;

      &:hover {
        background: #f1f2f4;
      }

      &:first-of-type {
        margin-top: 8px;
      }

      .ant-list-item-meta {
        align-items: center;
      }

      .ant-list-item-meta-title {
        margin: 0;
      }

      h4 {
        display: flex;
        align-items: center;
        .title {
          max-width: 135px;
        }
        span {
          margin-left: 5px;
          width: 6px;
          height: 6px;
          display: inline-block;
          border-radius: 50%;
          background: #ff2741;
        }
      }

      .time {
        color: var(--color_font_dark_65);
      }

      .ant-avatar {
        background-color: var(--color_primary);
      }

      .badge-item-content {
        height: 22px;
      }
    }
    .ant-list-footer {
      padding: 12px;
      .cardFooter {
        p {
          color: var(--color_font_dark);
          cursor: pointer;
          &:hover {
            color: var(--color_font);
          }
        }
      }
    }

    .ant-list-empty-text {
      border-bottom: 1px solid rgba(0, 0, 0, 0.051);
    }
  }

  .emptyContent {
    height: 160px;

    .anticon {
      font-size: 48px;
      margin-top: 30px;
    }

    p {
      color: #3d3d3d;
      margin-top: 12px;
    }
  }
}
