.pageContentHome {
  min-width: 1280px;
  background: #ffffff;
  height: 100%;
}

// Variables
@TextColor: rgba(0, 0, 0, 0.85);
@BannerTextColor: #ffffff;
@BgColor: #ffffff;
@BaseColor: #135eff;

@creativity: '../../assets/whale/';
@creativityBg: 'https://cdn-file.tradeplus.cc/';

.headerContainerHome {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    display: inline-block;
    height: 28px;
    margin-left: 24px;
  }
  .logoImage {
    cursor: pointer;
  }
  .headerLeftContent {
    display: flex;
    align-items: center;

    :global {
      .ant-tabs {
        .ant-tabs-nav {
          margin-bottom: 0;
          &::before {
            border: 0;
          }
        }
        .ant-tabs-ink-bar {
          height: 3px;
          border-radius: 4px;
        }
        .ant-tabs-tab {
          padding: 17px 0;
        }
      }
    }
    .headerLeftContentTab {
      margin-left: 56px;
    }
  }
  .headerRightContent {
    display: flex;
    margin-right: 24px;
    .rightContentBtn {
      display: inline-block;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #ffffff;
      text-align: center;
      //width: 95px;
      padding: 0px 10px;
      //height: 32px;
      line-height: 32px;
      border-radius: 16px;
      //background: #f4335e;
      background: #135eff;
      cursor: pointer;
    }
    .loginContainer {
      width: 850px;
      border-radius: 8px;
      height: 530px;
      background: #ffffff;
      .loginContainerImg {
        height: 530px;
      }
    }
    .languageChose {
      .Multilingual {
        margin-right: 24px;
        color: rgba(0, 0, 0, 0.45);
        cursor: pointer;
        position: relative;
      }
      span + span {
        &::before {
          content: '';
          position: absolute;
          top: 3px;
          left: -12px;
          width: 1px;
          height: 15px;
          background: rgba(0, 0, 0, 0.45);
        }
      }
      .language {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .avatar {
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
    vertical-align: middle;
    background: rgba(255, 255, 255, 0.85);
    img {
      // height: auto;
      height: 105%;
      margin-left: 0;
      width: 100%;
    }
  }
}

.BgPic {
  background-size: cover;
  position: relative;
}
.font36 {
  font-size: 36px;
}
.font32 {
  font-size: 32px;
}
.font24 {
  font-size: 24px;
}
.font20 {
  font-size: 20px;
}

.Banner_ydmb_1 {
  background: url('@{creativityBg}Banner_ydmb_1.png') no-repeat center;
  .BgPic();
}

.Banner_ydmb_2 {
  background: url('@{creativityBg}Banner_ydmb_2.png') no-repeat center;
  .BgPic();
}

.Banner_ydmb_3 {
  background: url('@{creativityBg}Banner_ydmb_3.png') no-repeat center;
  .BgPic();
}

.marginAuto {
  width: 1200px;
  margin: 0 auto;
}

.ColorBlock(@w:395px, @h:89px, @t:35px, @r:20px, @bg:#15e2ae) {
  position: absolute;
  content: '';
  width: @w;
  height: @h;
  background: @bg;
  top: @t;
  right: @r;
  transform: translateZ(-1px);
}

// banner轮播
.bannerContainer {
  position: relative;
  // height: 960px;
  background-color: @BgColor;

  .arrow {
    position: absolute;
    top: 45%;
    color: @BannerTextColor;
    font-size: 40px;
    z-index: 10;
    cursor: pointer;
  }

  .btnLeft {
    .arrow();
    left: 25px;
  }

  .btnRight {
    .arrow();
    right: 25px;
  }

  .ant-carousel {
    height: 646px;

    .slick-dots li {
      width: 52px;
      height: 6px;

      &.active {
        height: 8px;
      }
    }

    .slick-dots-bottom {
      bottom: 80px;
    }
  }

  .itemBanner {
    height: 646px;

    .itemBannerText {
      position: absolute;
      top: 30%;
      left: 60%;

      h3 {
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 5px;
        color: @BannerTextColor;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        margin: 16px 0 0 0;
        width: 490px;
        color: @BannerTextColor;
      }

      .ant-btn {
        margin-top: 80px;
        background-color: @BaseColor;
        color: @BannerTextColor;
        border-color: @BaseColor;
      }

      .ant-btn.ant-btn-lg {
        padding-left: 51px;
        padding-right: 51px;
      }
    }

    .bannerImg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.copyskyinkFlag {
  background: url('@{creativityBg}copywriting-ydmb.png') no-repeat center !important;
  background-size: cover !important;
}
//文案创作模块
.copyWriting {
  background: url('@{creativityBg}copywriting-1.png') no-repeat center;
  height: 1100px;
  .BgPic();

  .textContent {
    position: absolute;
    width: 490px;
    top: 260px;
    left: 60%;
    color: @TextColor;

    h3 {
      font-weight: 500;
      .font36();
      position: relative;
      transform-style: preserve-3d;
      &::before {
        .ColorBlock(@w:167px, @h:28px, @t: 30px, @bg:#135eff);
        //position: absolute;
        //content: '';
        //width: 167px;
        //height: 28px;
        //background: #135EFF;
        //top: 30px;
        //right: 20px;
        transform: translateZ(-1px);
      }
    }

    p {
      margin-top: 56px;
      text-align: right;
      position: relative;
      transform-style: preserve-3d;
      .font24();

      &::before {
        .ColorBlock();
        //position: absolute;
        //content: '';
        //width: 395px;
        //height: 89px;
        //background: #15E2AE;
        //top: 35px;
        //right: 20px;
        //transform: translateZ(-1px);
      }

      span {
        display: block;
        font-weight: 400;
        line-height: 56px;
      }
    }
  }
}

.titleContent {
  .marginAuto();
  padding-top: 100px;
  text-align: right;

  h3,
  p {
    color: @BannerTextColor;
    margin-bottom: 24px;
    line-height: 48px;
  }

  h3 {
    .font36();
    position: relative;
    transform-style: preserve-3d;
    &::before {
      .ColorBlock(@w:167px, @h:30px, @t: 40px, @r:0px, @bg:#135eff);
    }
  }

  p {
    .font24();
    position: relative;
    transform-style: preserve-3d;
    &::before {
      .ColorBlock(@w:274px, @h:20px,@t: -2px, @r:0px,);
    }
  }
}

//小红书
.redBook {
  background: url('@{creativity}xiaohongshubg.png') no-repeat center;
  .BgPic();
  height: 1050px;

  .textContent {
    .marginAuto();
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .leftContent {
    background: url('@{creativity}textList.png') no-repeat center;
    background-size: contain;
    position: relative;
    display: flex;
    width: 560px;
    height: 680px;
    color: @BannerTextColor;

    .title {
      margin-left: 75px;
      li {
        font-size: 24px;
        margin-bottom: 114px;
        margin-top: 116px;
      }
    }

    .subTitle {
      margin-left: 20px;
      margin-top: 26px;
    }
    .subTitle li {
      .font20();
      margin: 118px 0 119px 33px;
    }
  }

  .rightContent {
    width: 740px;
    height: 470px;
    position: relative;
    margin-top: 100px;
  }

  .groundFloor {
    width: 690px;
    height: 455px;
    position: relative;

    img {
      width: 100%;
    }
  }
}

.block {
  width: 550px;
  position: absolute;
  bottom: 0;
  right: 210px;
  .greenBlock {
    width: 371px;
    height: 18px;
    background: #15e2ae;
    margin-left: 177px;
  }
  .blueBlock {
    width: 548px;
    height: 36px;
    background: @BaseColor;
  }
}

//客户案例
.customerCase {
  .marginAuto();
  height: 1250px;
  position: relative;

  .titleContent {
    margin-bottom: 100px;
  }

  .titleContent p,
  .titleContent h3 {
    color: @TextColor;
  }

  .customerContent {
    width: 1120px;
    height: 690px;
    margin: 0 auto;
    display: flex;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 8px 48px 0px rgba(0, 0, 0, 0.16);
  }
  .customerPosition(@w:338px, @h:360px, @t:-228px, @l:-165px) {
    position: absolute;
    width: @w;
    height: @h;
    top: @t;
    left: @l;
  }

  img {
    .customerPosition();
  }

  .grayBlock {
    background-color: #15e2ae;
    .customerPosition(@w:276px, @h:276px, @t:495px, @l:-90px);
  }

  .left {
    width: 680px;
    z-index: 0;
    background: @BaseColor;
    border-radius: 16px 0 0 16px;

    h3,
    div,
    p,
    span {
      color: @BannerTextColor;
    }
  }

  .right {
    width: 452px;
    background: url('@{creativity}baby-bg.png') no-repeat center;
    height: 688px;
    .BgPic();
    border-radius: 0 16px 16px 0;
  }

  .left,
  .right {
    padding: 75px 48px;

    h3 {
      font-size: 30px;
      padding-bottom: 75px;
    }

    p.explain {
      padding: 0 0 63px;
    }
    p.subExplain {
      padding-bottom: 22px;
    }

    p {
      .font20();
      line-height: 34px;
    }
  }

  .block {
    bottom: 50px;
    .greenBlock {
      width: 558px;
      height: 24px;
    }
    .blueBlock {
      width: 249px;
      margin-left: 486px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      width: 150px;
      div {
        font-size: 38px;
        span {
          .font24();
        }
      }
    }
  }

  .left ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right li {
    padding-top: 36px;
  }
}

//关于我们
.aboutUs {
  background: url('@{creativity}aboutus.png') no-repeat center;
  .BgPic();
  height: 755px;

  .container {
    .marginAuto();
    position: relative;
    padding: 120px 0 100px;
  }

  .titleContent {
    width: 300px;
    position: absolute;
    //top: 30px;
    top: -58px;
    right: 0px;
    h3,
    p {
      color: @TextColor;
    }
    p {
      &::before {
        .ColorBlock(@w:31px, @h:31px, @t:-4px, @r: 0px, @bg:#474747);
      }
    }
  }

  .dialogbox {
    margin: 0 auto;
    background: url('@{creativity}dialogbox.png') no-repeat center;
    .BgPic();
    width: 940px;
    height: 550px;

    p {
      width: 832px;
      margin: 0 auto;
      padding: 85px 32px;
      .font20();
      color: #211361;
      line-height: 48px;
      span {
        display: block;
        text-indent: 50px;
        &:first-child {
          &::first-letter {
            .font36();
          }
        }
      }
    }
  }

  .crossBlock {
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 524px;
    height: 34px;
    background: @BaseColor;
  }

  .portraitBlock {
    position: absolute;
    left: 30px;
    bottom: 15px;
    display: flex;
    align-items: flex-end;

    .grayBlock {
      width: 43px;
      height: 421px;
      background: #001774;
    }

    .blueBlock {
      width: 43px;
      height: 196px;
      background: @BaseColor;
    }
  }
}

.more {
  margin: 0 auto;
  background: url('@{creativity}more.png') no-repeat center;
  .BgPic();
  height: 430px;

  div {
    position: absolute;
    top: 100px;
    left: 38%;
    width: 430px;
    margin: 0 auto;
    text-align: center;
    height: 175px;

    h3 {
      .font32();
      line-height: 50px;
      margin-bottom: 53px;
    }

    .ant-btn-lg {
      width: 432px;
      height: 72px;
      background: @BaseColor;
      border-radius: 16px 16px 16px 16px;
      span {
        .font24();
        color: @BgColor;
      }
    }
  }
}

.footerContainer {
  width: 1200px;
  margin: 0 auto;
  height: 205px;

  .footerBottom {
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;

    .logo {
      display: flex;
      align-items: center;
      img {
        width: 155px;
        display: flex;
        margin-right: 120px;
      }
    }

    .contact {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        margin: 0 60px 0 0px;
      }

      p {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.851);
        font-weight: 600;
      }

      .contactTitle {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 14px;
      }
    }

    .qr img {
      width: 92px;
    }
  }

  .bottomText {
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    padding: 20px 0;
    p {
      line-height: 18px;
    }
  }
}
