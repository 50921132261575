.h3_title {
  font-weight: 600;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
  margin: 0px 0 40px 0;
  text-align: center;
  padding-top: 20px;
}

.loginModal {
  .loginContainer {
    width: 800px;
    min-height: 530px;
    background: #ffffff;
    border-radius: 8px;
  }

  .loginContent {
    display: flex;
    position: relative;
  }

  .loginLeft {
    height: 530px;
    width: 350px;
    background: url('https://cdn-file.tradeplus.cc/login .png') no-repeat center;
    background-size: cover;
    overflow: hidden;
    border-radius: 6px 0px 0px 6px;
  }
  .loginRight {
    flex: 1;
    padding: 0 55px;

    .loginCancle {
      position: absolute;
      right: -35px;
      top: -30px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: white;
      font-size: 22px;
    }
  }

  .formBotton {
    .ant-btn {
      width: 100%;
      background-color: #135eff;
      border-color: #135eff;
      border-radius: 4px;
      &:not(:disabled):hover {
        background-color: #135eff;
      }
    }
  }
  .btnIdentitySwitch {
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: 1;
    width: 78px;
    height: 78px;
    color: #bfbfbf;
    cursor: pointer;
    img {
      width: 100%;
    }
    .switchTip {
      position: absolute;
      right: 56px;
      top: 28px;
      padding: 5px 11px;
      border-radius: 6px;
      font-size: 14px;
      color: var(--color_primary);
      line-height: 32px;
      text-align: center;
      background: rgba(75, 109, 246, 0.1);
      white-space: nowrap;
      &:before {
        content: '';
        position: absolute;
        right: -8px;
        top: 31%;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid #edf0fe;
      }
    }
    &:hover {
      color: #f4335e;
    }
  }
  .ant-input {
    background: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
  .loginTabs {
    margin-top: 70px;
    position: relative;

    h3 {
      .h3_title();
    }

    .sendCodeFormItem {
      .ant-btn.ant-btn-lg {
        padding-left: 8px;
        padding-right: 8px;
      }

      .ant-btn-default:not(:disabled):hover {
        color: var(--color_primary);
        border-color: var(--color_primary);
      }
    }

    .verification-content {
      margin-bottom: 48px;
      .ant-form-item:last-of-type {
        margin-bottom: 12px;
      }

      .text-login {
        color: var(--color_primary);
        cursor: pointer;
      }
    }
  }

  .wxQrCodeContent {
    width: 210px;
    margin: 0 auto;
    .wxQrCode {
      background-color: #f4f4f4;
      padding: 12px;
      box-shadow: 0px 4px 10px 0px rgba(70, 70, 70, 0.1);
      border-radius: 16px;
      position: relative;
    }
    .expired {
      width: 210px;
      height: 210px;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      .refresh {
        color: #ffffff;
        text-align: center;
        padding-top: 55px;
        width: 150px;
        margin: 0 auto;

        .anticon-sync {
          font-size: 40px;
          display: block;
        }
        span {
          margin: 8px 0;
        }
      }
    }
  }

  .agreeServe {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    .square {
      width: 13px;
      height: 13px;
      border: 1px solid #177eff;
      border-radius: 1px;
      box-shadow: 0px 0px 2px 0px rgba(43, 128, 255, 0.9);
      margin-right: 10px;
      cursor: pointer;
    }

    .span {
      color: #177eff;
      margin-right: 8px;
      font-size: 15px;
    }

    > span {
      color: #177eff;
      cursor: pointer;
      font-size: 12px;
      margin: 0 2px;
    }
  }

  .tips {
    font-size: 12px;
    color: red;
    margin-left: 14%;
  }

  .goBackLogin {
    text-align: center;
    color: var(--color_primary);

    span {
      cursor: pointer;
    }
  }
}

.ceshi {
  font-size: 20px;
  background-image: -webkit-linear-gradient(bottom, red, #fd8403, yellow);
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
