.consult .ant-modal-content {
  .consultContent {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 32px 0;
    height: 145px;
    background: rgba(216, 216, 216, 0.3);
    border-radius: 10px;

    img {
      width: 108px;
      height: 108px;
    }

    p span {
      display: block;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }
}
