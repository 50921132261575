.messageModal {
  .ant-modal-content {
    border-radius: 24px;
  }

  .ant-modal-body {
    height: 400px;
    //overflow: hidden;

    h3 {
      padding: 0 12px;
      font-size: 16px;
      font-weight: bold;
      margin-top: 8px;
    }

    .ql-editor {
      height: calc(100% - 25px);
      img {
        display: inline-block;
        width: 100%;
        margin-top: 16px;
        border-radius: 10px;
      }
    }
  }

  .ant-modal-footer {
    text-align: center;
    margin-top: 24px;
    .ant-btn {
      width: 346px;
      height: 46px;
      background: var(--color_primary);
      border-radius: 100px;
    }
  }
}
