// Variables
@TextColor: rgba(0, 0, 0, 0.85);
@titleColor: #135eff;
@BgTextColor: #ffffff;
@red: #ff2741;
@recharge: '../../assets/recharge/';

.BgPic(@p: absolute) {
  background-size: cover;
  position: @p;
}

h3,
h4 {
  margin: 0;
  padding: 0;
}

.recharge .ant-modal-content,
.payInfo .ant-modal-content {
  background: #f3f5f9;
  height: 635px;
  border-radius: 16px;
}

.recharge {
  .ant-modal-content {
    padding: 0 16px 16px;
  }

  .modalHeader {
    position: relative;
    height: 115px;
    text-align: center;

    h3 {
      background: url('@{recharge}taoc.png') no-repeat center;
      .BgPic();
      height: 78px;
      width: 270px;
      margin: 0 290px;
      top: -17px;
      font-size: 24px;
      text-align: center;
      line-height: 95px;

      span {
        color: @titleColor;
      }
    }
    p {
      color: rgba(0, 0, 0, 0.65);
      padding-top: 70px;
    }
  }

  .rechargeContent {
    display: flex;
    justify-content: space-between;
  }

  .itemRecharge {
    width: 264px;
    height: 500px;
    background: @BgTextColor;
    border-radius: 16px;

    .itemHead {
      position: relative;

      .tag {
        position: absolute;
        top: -4px;
        right: 0;
        padding: 6px 8px;
        border-radius: 16px 16px 16px 0;
        display: inline-block;
        color: #ffffff;
      }

      img {
        width: 100%;
        height: 120px;
      }

      .itemTitle {
        position: absolute;
        top: 20px;
        left: 16px;
        color: @BgTextColor;
        width: 235px;

        h3 {
          font-size: 20px;
          line-height: 35px;
        }
        p {
          margin-top: 5px;
        }
      }
    }

    .itemPrice {
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      border-bottom: 1px dashed #d8d8d8;

      .price {
        margin: 24px 0 13px;
        font-weight: 600;
        line-height: 20px;
        transition: 0.3s;
        color: @red;

        span {
          margin-left: 8px;
          font-size: 40px;
        }
      }

      .noOriginPrice {
        height: 78px;
      }

      .original_price {
        font-size: 16px;
        margin-bottom: 22px;
      }

      .companyPrice {
        height: 115px;
        line-height: 115px;
        font-size: 16px;
      }

      .ant-btn.ant-btn-lg {
        width: 165px;
        margin: 0 auto 24px;
        border-color: transparent;
        box-shadow: none;
        color: @BgTextColor;
        border-radius: 8px;
        &:hover {
          color: @BgTextColor;
        }
      }
    }
  }
}

.itemText {
  p {
    margin: 12px 20px;
    &:first-child {
      margin-top: 22px;
    }
  }
  span {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: #135eff;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.yunduoIcon {
  width: 16px;
  height: 16px;
  vertical-align: sub;
  margin-left: 5px;
}
