.sider-menu {
  width: 100%;
  margin-inline: 0;
  font-weight: 500;
  border-inline-end: 0 solid rgba(5, 5, 5, 0.06) !important;

  .ant-menu-submenu-title {
    // padding-left: 0 !important;
  }

  .ant-menu-title-content .beta {
    height: 16px;
    vertical-align: text-bottom;
    margin-left: 5px;
  }

  .ant-menu-item-only-child {
    .ant-menu-title-content {
      padding-left: 12px;
    }
  }

  .ant-menu-vertical {
    border-inline-end: 0 !important;
  }

  .ant-menu.ant-menu-inline,
  .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
  }

  .ant-menu-inline {
    background: transparent;
  }

  .ant-menu-vertical {
    border-inline-end: 0 solid rgba(5, 5, 5, 0.06);
  }
}
