.mainLayout {
  height: 100%;
  overflow: hidden;

  .header {
    padding-inline: 24px;
    background: #fff;
    border-bottom: 1px solid rgb(0 21 41 / 6%);
    max-height: 60px;
    min-height: 60px;
  }

  .sider {
    padding: 24px 8px 0;
    // margin: 24px 0 0 24px;
    background: #fff;
    position: relative;
    // border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &.padding0 {
      padding: 0;
    }

    ul {
      position: relative;
      z-index: 10;
    }

    .collapsed {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 10px 10px 20px;
      width: 100%;
      text-align: right;
      font-size: 15px;
      background-color: transparent;
      // border-top: 1px solid #eee;
      cursor: pointer;
    }
  }

  .service-qr {
    position: absolute;
    bottom: 35px;
    padding: 0 46px;
    text-align: center;
    img {
      width: 100%;
    }
    p {
      font-size: 12px;
      color: var(--color_font_dark);
    }
  }

  .layoutContent {
    // padding: 24px 24px 0 24px;
    overflow: auto;
    background-color: #fff;

    &.layoutBgColor {
      background-color: #f3f5f9;
    }
  }

  .content {
    flex: 1;
  }

  .footer {
    font-size: 14px;
    color: rgb(0 0 0 / 45%);
    text-align: center;
  }
}
