.MenuItem {
  font-size: 14px;
}

.avatarContainer {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  flex-direction: column;

  .avatar {
    cursor: pointer;
  }

  .avatar_logo {
    width: 50px;
    position: absolute;
    bottom: -5px;
  }

  p {
    margin: 0;
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgb(0 0 0 / 85.1%);
  }
}

.avatarContainer:hover {
  .avatarContent {
    opacity: 1;
    z-index: 1038;

    .card {
      width: 293px;
      height: 208px;
    }
  }

  .avatarContent2.show {
    opacity: 1;
    right: 293px + 10px;
    z-index: 1038;

    .card1 {
      width: 293px;
      height: auto;
    }
  }
}

.avatarContent {
  position: absolute;
  top: 43px;
  right: 0px;
  width: auto;
  height: auto;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .card {
    margin: 0 8px 16px 8px;
    width: 0;
    height: 0;
    transition: all 0.1s ease-in-out;
    border-radius: 8px;
    box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.05);

    .ant-card-body {
      padding: 8px;
    }

    .cardText {
      cursor: pointer;
      width: 100%;
      height: 40px;
      padding-left: 12px;
      color: var(--color_font);
      font-weight: 600;
      border-radius: 8px;

      &:hover {
        background-color: #f1f2f4;
      }
    }
  }
}

.dividerV {
  margin: 9px 0 9px 0;
}

.dividerV1 {
  margin: 9px 0 14px 0;
}

.cardItem {
  width: 100%;
  height: 79px;
  border-radius: 8px;
  padding: 16px 12px;
  cursor: pointer;

  .avatarImage {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 16px;

    > img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .avatarText {
    flex: 1;

    .avatarText1 {
      display: flex;
      align-items: center;

      .title {
        color: var(--color_font);
        font-size: 14px;
        font-weight: 600;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
      }

      .img {
        height: 20px;
      }
    }

    .avatarText2 {
      color: var(--color_font_dark);
      font-size: 12px;
      font-weight: 400;
      margin-top: 4px;

      .company_name {
        width: 140px;
        display: inline-flex;
      }
    }
  }

  .avatarIcon {
    cursor: pointer;

    .icon {
      font-size: 16px;
    }
  }
}
.disabledCardItem {
  cursor: not-allowed;
  background-color: #f1f2f4;
}

.cardItem.marginTop {
  margin-top: 10px;
}

.cardItem:hover {
  background-color: #f1f2f4;
}

.cardItem.selected {
  background-color: #f1f2f4;
}

.avatarContent2 {
  position: absolute;
  top: 45px;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  border-radius: 8px;

  .card1 {
    margin: 0 20px 16px 8px;
    width: 0;
    height: 0;
    transition: all 0.1s ease-in-out;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    min-height: 110px;

    .ant-card-body {
      padding: 8px 0;
    }

    .cardContent {
      max-height: 550px;
      overflow-y: auto;
      padding: 0 8px;
    }
  }
}
.chooseAccoutModal {
  .ant-modal-content {
    padding: 24px 24px 16px;
    .ant-card-bordered {
      border: 0;
    }
    .ant-card-body {
      padding: 16px 0 8px 3px;
      .cardContent {
        .cardItem {
          padding: 16px;
          width: auto;
          height: 48px;
          .avatarText2 {
            display: flex;
            align-items: flex-end;
            img {
              width: 16px;
              height: 16px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}
