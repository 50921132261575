// 设置 自有项目开发代码的全局样式  大致和antd 一样
:root {
  // 主题色
  --color_primary: #135eff;
  // 用于一些选中背景
  --color_Primary_bg: #135eff20;
  //字体颜色 亮色
  --color_font: rgba(0, 0, 0, 0.85);
  //字体颜色 暗色
  --color_font_dark: rgba(0, 0, 0, 0.45);
  //字体颜色 暗色1度
  --color_font_dark_65: rgba(0, 0, 0, 0.65);
  // 用于全局圆角
  --border_radius: 2px;
  // 全局背景色
  --background_primary: #f3f5f9;
}

// menu  菜单栏  icon
.menu-icon {
  font-size: 16px !important;
}

// 布局
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.flex-row-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.flex-row-start-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
}

.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.flex-row-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.flex-row-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex-row-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
}

.flex-row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.flex-column-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.flex-column-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.flex-column-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-column-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.flex-column-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.flex-column-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-ellipsis-2 {
  display: -webkit-box; /* 将元素作为块容器 */
  overflow: hidden; /* 隐藏超出部分内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  -webkit-line-clamp: 2; /* 限制最多显示两行文字 */
  -webkit-box-orient: vertical; /* 垂直布局 */
}

.text-ellipsis-4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.ant-image-preview-root .ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
}
.ant-tooltip .ant-tooltip-inner {
  color: #f3f5f9;
}
.ant-btn {
  gap: 0;
}

.ant-image-preview-operations,
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  background-color: rgba(0, 0, 0, 0.5);
}

.ant-image-preview-switch-left {
  inset-inline-start: 180px;
}

.ant-image-preview-switch-right {
  inset-inline-end: 180px;
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  width: 48px;
  height: 48px;
}
