.text-copy {
  width: 100%;

  p {
    display: inline-block;
    text-align: center;
    width: 62px;
    height: 24px;
    line-height: 24px;
    background: #f1f2f4;
    border-radius: 4px 4px 4px 4px;
    cursor: pointer;
    margin-left: 12px;

    span {
      margin-right: 4px;
    }
  }
}
