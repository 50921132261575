.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .leftContent {
    display: flex;
    align-items: center;

    .appLogo {
      height: 30px;
      margin-right: 8px;
    }

    .fcLogo {
      height: 45px;
      margin: 0 40px 0 35px;
    }

    .icon {
      position: relative;
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        .iconFont {
          opacity: 1;
        }

        .iconFont1 {
          opacity: 0;
        }
      }

      .iconFont {
        position: absolute;
        opacity: 0;
        top: 2px;
        font-size: 21px;
        transition: all 0.3s ease-in-out;
      }

      .iconFont1 {
        position: absolute;
        top: 2px;
        font-size: 21px;
        transition: all 0.3s ease-in-out;
      }
    }

    .icon:hover {
      // 处理hover
      .moreTool {
        opacity: 1;
        z-index: 1028;

        .card {
          height: 244px;
        }
      }
    }
  }

  .centerContent {
    padding: 3px 18px;
    height: 36px;
    background-color: rgba(123, 94, 255, 0.05);
    border-radius: 36px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .centerItem {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 16px;
      height: 100%;

      &.pointer {
        cursor: pointer;
      }
    }

    .image1 {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }

    .image2 {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }

    .iconFont1 {
      color: var(--color_primary);
      font-size: 14px;
      font-weight: 600;
    }

    .iconFont2 {
      color: var(--color_font);
      font-size: 14px;
      font-weight: 400;
    }

    .iconFont3 {
      color: var(--color_font_dark);
      font-size: 14px;
      font-weight: 400;
      margin-right: 6px;
    }

    .icon {
      color: var(--color_primary);
      font-size: 18px;
    }
  }

  .rightContent {
    min-width: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div.language {
      margin-right: 20px;

      span {
        color: rgba(0, 0, 0, 0.45);
        cursor: pointer;

        strong {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    .divider {
      height: 30px;
      margin: 0 12px 0 16px;
    }

    .qa {
      height: 42px;
      line-height: 42px;
      padding: 2px 8px;
      border-radius: 8px;
      color: rgba(0, 0, 0, 0.85);

      .anticon {
        font-size: 21px;
        line-height: 42px;
        height: 42px;
      }

      &:hover {
        background: #f1f2f4;
      }
    }
  }

  .moreTool {
    position: fixed;
    top: 55px;
    left: 110px;
    width: auto;
    height: auto;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .card {
      width: 284px;
      height: 0;
      transition: all 0.1s ease-in-out;
      border-radius: 8px;

      .ant-card-body {
        padding: 16px;
      }

      .title {
        color: var(--color_font);
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .cardItem {
        width: 100%;
        height: 68px;
        border: 1px solid #f1f2f4;
        cursor: pointer;
        margin-top: 16px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .left {
          .image {
            width: 32px;
            height: 32px;
          }
        }

        .right {
          flex: 1;
          margin-left: 16px;
          height: 100%;

          .text1 {
            color: var(--color_font);
            font-size: 14px;
            font-weight: 600;
          }

          .text2 {
            color: var(--color_font_dark);
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .cardItem.disabled {
        background-color: #f1f2f4;
      }

      .cardItem:hover {
        background-color: #f1f2f4;
      }
    }
  }
}

.drop-target-item {
  display: inline-block;
  height: 35px;
  line-height: 35px;
  width: 130px;
  text-align: center;

  &:hover {
    color: var(--color_primary) !important;
  }
}
