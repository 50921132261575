.chooseAccoutModal {
  .ant-modal-content {
    padding: 24px 24px 16px;
    .ant-card-bordered {
      border: 0;
    }
    .ant-card-body {
      padding: 16px 0 8px 3px;
      .cardContent {
        .cardItem {
          padding: 16px;
          width: auto;
          height: 48px;
          .avatarText2 {
            display: flex;
            align-items: flex-end;
            img {
              width: 16px;
              height: 16px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}
