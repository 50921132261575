body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  white-space: pre-wrap;
}

#root {
  min-width: 1280px;
  height: 100vh;
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

/*
 滚动条样式 chrome
*/
::-webkit-scrollbar {
  width: 6px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 6px;
}

::-webkit-scrollbar-thumb {
  /* border: 4px solid #ffffff; */

  /* box-shadow: 9px 9px 9px 0px rgba(68, 68, 68, 0.5) inset; */
  background: #d8d8d8;
  border: 1px solid #d8d8d8;

  /* 滚动条里面小方块 */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: #fff;

  /* 滚动条里面轨道 */
  border-radius: 6px;
}
